.inbox-message-container {
    display: flex;
    align-items: center;
    min-height: 100%;
    width: 100%;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 1rem;
    user-select: none;
}

.inbox-msg-list {
    padding-left: 1em;
    list-style-type: none;
}
.inbox-msg-list li {
    margin-bottom: 1.4em;
}

.inbox-message-unread {
    font-weight: bold;
}

.inbox-date-container {
    width: 20%;
    margin-right: 1em;
    color: #585858;
    font-size: 0.7em;
    text-align: right;
}

.inbox-date-container  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.inbox-date-container  li {
    text-align: right;
}

.inbox-content-container {
    width: 70%;
    font-size: 0.9em;
}

.inbox-content-container, .inbox-date-container, .inbox-content-redirection {
    height: 100%;
    display: inline-block;
}

.inbox-no-redirect {
    visibility: hidden;
}

.inbox-content-redirection {
    width: 5%;
    height: 30px;
    align-self: center;
    display: flex;
    align-items: center;
}

.inbox-content-pointer {
    cursor: pointer;
}

.inbox-content-title {
    font-size: 1.4em;
    color: #005EA7;
}
