.readPdf {
    display: flex;
	justify-content: start;
	align-items: center;

    span {
		flex: 1;
		margin-bottom: 9px;
	}
}
