@import "../../../data/config-scss/variables.scss";

.note-modal {
	padding: 5%;
	padding-top:0;
}
.note-modal-title {
	margin-bottom: 0.5em;
	text-align: center;
	font-size: 1em;
	color: dimgrey;
}
.note-modal-textarea {
	width: 100%;
    min-width: 200px;
	height: 180px;
	resize: none;
	position: relative;
	margin: auto;
	padding: 10px;
	box-sizing: border-box;
	border: solid lightgrey;
}
@media (min-aspect-ratio:1/2) and (max-width:400px){
	.note-modal-textarea {
		height:125px;
	}
}
.note-modal-buttons {
	margin-top:10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.note-modal-button {
	cursor:pointer;
	background-color:$color2;
	color:$titleFontColor;
	padding:5px;
	border-radius:5px;
}
#note-modal-deleteButton {
}
#note-modal-closeButton {
}

.share-btn-mail {
	color: #009766;
}
.share-btn-facebook {
	color: #4267b2;
}
.share-btn-linkedin {
	color: #0077b5;
}
.share-btn-twitter {
	color: #1da1f2;
}
