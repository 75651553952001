@import "../../../data/config-scss/variables.scss";

.fiche-description {

    position: relative;

    .logo {
        img {
            margin-top: 1%;
            max-width: 100%;
        }
    }

    .img-full-width img {
        position : relative;
        left     : 50%;
        max-width: 100%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .img-left {
        float: left;
        margin-right: 10px;

        img {
            max-width: 150px;
            height: auto;
        }
    }

    .prop-name {
        margin-bottom: 8px;
        overflow: auto;

        .bottom-shadow {
            -webkit-box-shadow: inset 0px -41px 38px 0px rgba(255, 255, 255, 1);
            -moz-box-shadow: inset 0px -41px 38px 0px rgba(255, 255, 255, 1);
            box-shadow: inset 0px -41px 38px 0px rgba(255, 255, 255, 1);
            height: 200px;
            width: 100%;
            z-index: 100;
            position:absolute;
        }

        .prop-description {
            padding: 1.1em;
            user-select: none;
            position: relative;
            box-sizing: border-box;

            &.preview {
                max-height: 200px;
                overflow: hidden;
                z-index: 0;
            }

            p {
                font-size  : 1.05em;
                margin     : 0 5px 0 5px;
                text-align : left;
            }
        }

        .btn-expand {
            float: right;
            cursor: pointer;
            text-decoration: underline;
            color: $color3;
            margin-right: 2.2em;
            line-height: 2em;
        }
    }
}
