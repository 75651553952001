
.notif-dialog {
    .title-font {
        text-align : center;
        font-weight: bold;
    }

    p {
        text-align: center;
    }
}