
.kl-content-wrapper {
    flex: 1 1 75%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.kl-content {

}
