@import "../../../data/config-scss/variables.scss";

$margin: 2px;
$labelPadding: 0.7em 0 0em 0.9em;
$inputPadding: 0.7em 0.77em;

#ud-form {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  margin: 1em auto;
  .generic-input {
    display: inline-block;
    margin: $margin;
    color: rgb(180, 105, 105);
    border: none;
    // border-bottom: 1px solid #eee;
    padding: $inputPadding !important;
    &:read-only {
      border: none;
      color: $lightGrey;
      &::placeholder{
        color: $lightGrey
      }
    }
    &.edit {
      &:read-only {
        color: black;
        border-bottom: 1px solid $lightGrey;
        &::placeholder {
          color: #555
        }
      }
    }
  }
}
.form-input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  label {
    // text-transform: capitalize;
    padding: $labelPadding;
  }
  .generic-input {
    padding: $inputPadding;
    flex: 1;
  }
}

#ud-container {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  flex-shrink: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  transition: opacity 0.5s;

  &.ud-form-saving {
    opacity: 0.7;
    pointer-events: none;
  }

  .generic-btn-container {
    margin: 3em 0 2em;
  }

  .cta-btn {
    font-size: 0.9em;
    &.-round {
      // add from ui project zeplin
      color: black;
      border: 1px solid $ctaBtnBackgroundColor;
      font-weight: 300;
      background-color: transparent;
      border-radius: 2rem;
      box-shadow: inset 0 0 5px rgba(#000, 0.16);
      padding: 0.6em 1.5em
    }
  }
  .cta-btn:active {
    background-color: $color1lighter;
  }

}

.ud-section-fields {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.8em;
}

.ud-ext-ressources {
  margin-bottom: 0.5em;
  line-height: 2em;
  text-align: center;
  user-select: none;

  a {
    border: 1px solid currentColor;
    border-radius: 4px;
    padding: 0.5em 0.7em 0.4em;
    font-weight: bold;
  }
}

.ud-link-container {
  margin-top: 1em;
  text-align: center;
  user-select: none;

  a {
    padding: 0.7em;
    line-height: 2em;
  }
}



.input-with-icon {
  flex: 1;
  display: flex;
  align-items: center;
  border-bottom: 1px #eee solid;
  i {
    margin: 0 1em;
  }
}

.ud-section-switches {
  border: none;
  border-top: 1px solid $color1;
  border-bottom: 1px solid $color1;
}