@import "../../../data/config-scss/variables.scss";

$margin: 4px 8px 4px 8px;
$horizontalSeparatorBorder: 1px solid #eee;
.notes-content {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
}
.notes-button-container {
	div.share-btn{
		color:$color2 !important;
	}
    .notes-button{
        cursor:pointer;
    }
}
.notes-options-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .download-btn {
        display: inline-block;
    }
    padding-top:.4em;
}

[id=notes-page] .favorites-content {
    -webkit-overflow-scrolling: touch;
}
