@import "../../data/config-scss/variables.scss";
@import "./form.scss";
@import "./generic-modal.scss";
@import "./animations.scss";

.smartbanner {
  z-index: 1;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
  // padding-top: constant(safe-area-inset-top);
  // padding-top: env(safe-area-inset-top);
  user-select: none !important;
}
.app-toolbar-children {
  .content-font {
    user-select: text !important;
  }
}
.is-ios body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

[id="app-root"] {
  height: 100%;
  width: 100%;
}

[id="app-root"].app-ready {
  background-color: $pageBackgroundColor;
}
.page-container {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

// Two columns mode
.page-container.left {
  z-index: 2 !important; // for border-right to be visible
  width: $leftColumnsWidth;
  border-right: 1px solid silver;
}
.page-container.right {
  width: $rightColumnsWidth;
}
.page-container.right {
  left: auto !important;
  right: 0;
}

.page__background {
  background-color: $pageBackgroundColor;
}

.flex-ellipsis {
  min-width: 0;
}

.clickable {
  cursor: pointer;
}

.text-color1 {
  color: $color1;
}

.icon-color1 {
  background-color: $color1;
}

.icon-color2 {
  background-color: $color2;
}

.icon-appToolbarColor {
  background-color: $appToolbarColor;
}

.icon-homeToolbarColor {
  background-color: $homeToolbarColor;
}

.link {
  text-decoration: none;
  cursor: pointer;
}
.link.empty-link {
  cursor: default;
}
.link.icon-font {
  color: grey;
}
.icon-font {
  -webkit-tap-highlight-color: transparent;
}
.colored-link {
  color: $linkColor !important;

  &:hover {
    cursor: pointer;
  }
}
a {
  -webkit-tap-highlight-color: transparent;
}

.list-empty {
  text-align: center;
  margin-top: 100px;
  font-size: 1em;
  color: $fdDarker;
}

// Fix IE et EDGE<16
.is-ms {
  .page__content {
    background-color: $pageBackgroundColor !important;
  }
  [id="home-page"] .page__content {
    background-color: transparent !important;
  }

  ons-splitter-side {
    background-color: $pageBackgroundColor;
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
  screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 2),
  screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
  screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .page__content {
    top: 0 !important;
    padding-top: env(safe-area-inset-top);
  }
}

// Confirm & Prompt dialogs

// on android: avoid modal being hidden by keyboard (due to adjustPan behaviour)
// on iOS: avoid webview pan, because of events position bug (see iosHackScrollTo0)
.dialog-container {
  z-index: 1400 !important; // above material ui dialogs/drawer
}
.dialog-top {
  top: -20%;
}
.dialog-title {
  font-size: 1.3em !important;
}

.linkedin-icon {
  text-decoration: none;
}

.scrollbars-hidden {
  // Hide horizontal scrollbar
  // chrome & safari
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    height: 0 !important;
    background-color: transparent;
  }
  // ffox
  scrollbar-width: none;
  // ie
  -ms-overflow-style: none;
}
//ios after cordova-camera open
.is-ios {
  .page-container {
    padding-top: env(safe-area-inset-top);
    --safe-area-inset-top: env(safe-area-inset-top);
    height: calc(100% - var(--safe-area-inset-top));
    #home-content {
      height: calc(100% - var(--safe-area-inset-top));
    }
  }
  .MuiDrawer-paperAnchorRight {
    padding-top: env(safe-area-inset-top);
  }
}
