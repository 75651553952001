
.ud-contacts {

    .search-field-container {
        padding: .3em .3em 0;
    }

    .fiche-separator {
        display: none;
    }
}