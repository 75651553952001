

.list-dialog-content {
    flex-shrink: 1;
    overflow-y : auto;
    -webkit-overflow-scrolling: touch;

    .list-component {
        font-size: .9em;
        overflow : visible;
    }
}