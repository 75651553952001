
@import "../../../data/config-scss/variables.scss";

$ctaBtnPadding: .3em .7em .2em !default;

.cta-btn-container {
    display: flex;
    flex-shrink: 0;
    margin : 0 auto;
    padding: .3em 0;
    justify-content: center;
}

.cta-btn {
    padding       : $ctaBtnPadding;
    font-weight   : bold;
    text-align    : center;
    text-transform: none;
    text-decoration-line: none;
    color         : white;
    border-bottom : 1px solid darken( $ctaBtnBackgroundColor, 10% );
    border-radius : 3px;
    cursor        : pointer;
    background-color: $ctaBtnBackgroundColor;
    transition    : opacity .4s;
    -webkit-tap-highlight-color: transparent;
}

.cta-btn-icon {
    background-color         : white;
    fill: white;
    mask-size: 100%;
}

.cta-btn-disabled {
    opacity: .5;
    cursor : default;
}