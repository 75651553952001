
@import "../../../data/config-scss/variables.scss";


[id=profile-page-content] {
    display         : flex;
    flex-direction  : column;
    height          : calc(100% - 10px); // NB: 10px = total vertical padding
    padding         : 5px 3px;
    color           : white;
    background-color: $pageBackgroundColor;
}

.is-ios.is-cordova [id=profile-page-content] {
    position: fixed;
    width: 100%;
}

.profile-select-label {
    color: $color1;
    margin: 0 .4em .2em;
    font-size: 1.3em;
    user-select: none;

    span {
        padding: .2em .3em .1em;
        border-radius: 0.2em;
        background-color: rgba(255, 255, 255, .6);
    }
}
