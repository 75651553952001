.ud-eBadge {
    display: flex;
    margin: 1em;
    border: 0;
    height: 100%;
    iframe {
        border: none;
        height: 100%;
        width: 100%;
    }
}