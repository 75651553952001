
/**
 * Generated by Animista
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */

.rotate-center {
    -webkit-animation: rotate-center 2s linear infinite both;
            animation: rotate-center 2s linear infinite both;
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
