
[id=full-loader] {

    position: absolute;
    top     : 0;
    left    : 0;
    z-index : 4; // lower than intersticiel for a cleaner startup
    width   : 100%;
    height  : 100%;
    display : flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(230, 230, 230, .7);


    .simple-loader {
        padding-top: 0;
    }

    ons-progress-circular {
        width : 100px !important;
        height: 100px !important;
    }
}


[id=full-loader].dark {
    background-color: rgba(13, 29, 59, .7);
}