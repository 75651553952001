
@import "../../../data/config-scss/variables.scss";

div.note-btn {
    margin   : .2em .2em 0;
    font-size: 2em;
    min-width: 1em;
    min-height: 1em;
    width: 1em;
    color    : $shareBtnColor;
    cursor   : pointer;
}
@media (min-width:768px) {
    div.note-btn{
        font-size:1.4em;
        min-width: 1.4em;
        min-height: 1.4em;
    }
}
