
#notifications-container {
    position: absolute;
    z-index : 30000; // Higher than onsen modals
}

.notification-title,
.notification-message {
    text-align: center;
}

.notification-message {
    ul {
        padding: 0;
        list-style: none;
        margin: 0.5em 0 0;
    }
}

// Allow clicks through the notifications container padding
.notifications-tc {
    pointer-events: none;

    .notification {
        pointer-events: auto;
    }
}