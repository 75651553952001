
@import "../../../data/config-scss/variables.scss";


#list-groups-page .remaining-height-container {
    display: flex;
    flex-direction: column;
}

.list-groups-container {
    display: flex;

    .items-list {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .side-index {
        min-width: 3em;
    }
}
