
.input-modal {
    transition: max-height .4s;
}

.input-modal-inner {
    display: flex;
    flex-direction: column;
    min-height: 0;
    min-width: 210px;

    input {
        margin: 0 1em .5em;
    }

    .generic-btn-container {
        flex-shrink: 0;
        margin: .7em 0 .9em;
    }

    .generic-field-row {
        align-items: stretch; // fix scroll content on searchTaiga
    }

    .generic-input {
        margin: 0;
        text-align: center;
    }
    .field-without-border-bottom {
        border-bottom: none;
    }
    .field-text-align-left {
        text-align: left;
    }
}

.input-modal textarea {
    width: 80%;
    // height: 10em; // modal buttons are not visible when keyboard is displayed
    padding: 1em .8em;
    font-size: 1em;
    min-height: 60px;
}

.input-modal input[type="date"] {
    display: flex;
    justify-content: center;
    -webkit-appearance: none;
    background-color: white;
}
