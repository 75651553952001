

.fiche.newproduct {

    .prop-left {
        display        : flex;
        align-items    : center;
        flex-basis     : 29%;
        justify-content: center;
        margin-left    : 13px;
    }
}