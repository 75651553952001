@import "../../data/config-scss/variables.scss";

.generic-modal-content {
  // max-width: 80%;
  padding: 0 1em 0.75em 1em;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modal-title {
  color: $color1;
  text-align: center;
}

.generic-btn-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0.5em;
  clear: both;
  user-select: none;

  & > div {
    display: flex;
    align-items: center;
  }
}

.generic-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6em 0.9em;
  text-transform: none;
  color: $color1; // change to themeColor
  box-shadow: inset 0 0 2px rgba(#000, 0.16);
  border: 1px solid $color1; // change to themeColor
  border-radius: 3px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:active {
    background-color: darkgrey;
    color: lightgrey;
  }

  &.active {
    background-color: darkgrey;
    color: lightgrey;
  }
}

.generic-btn-cancel {
  padding: 0.4em 0.7em 0.3em;
  text-transform: none;
  color: grey;
  border: 1px solid lightgrey;
  border-radius: 3px;
  cursor: pointer;
}

.cta-modal-btn {
  color: white;
  font-weight: bold;
  background-color: $ctaBtnBackgroundColor;
  max-width: 300px;
  &.-round {
    // add from ui project zeplin
    color: black;
    border: 1px solid $ctaBtnBackgroundColor;
    font-weight: 300;
    background-color: transparent;
    border-radius: 2rem;
    margin-bottom: 2rem;
    box-shadow: inset 0 0 5px rgba(#000, 0.16);
  }
}

.modal-btn-disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.generic-modal-content.dialog-with-inputs {
  top: 42px;
  transform: translateX(-50%) !important;
}

[role="dialog"] .menu-item-switch {
  margin-left: 0.8em;
}

.dialog-min-width .MuiPaper-root {
  width: calc(100% - 32px); // 32px = left+right margins
  max-width: 600px;
  margin-right: 16px;
  margin-left: 16px;
}

// Sweet alert
// Confirm and prompt buttons
.swal2-confirm {
  font-weight: bold !important;
  background-color: $ctaBtnBackgroundColor !important;
}
.swal2-cancel {
  font-weight: bold !important;
  // background-color:
}

.swal2-show-override {
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: swal2-show-override;
}
@keyframes swal2-show-override {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

.swal2-popup {
  .swal2-actions {
    position: sticky;
    bottom: -0.625em;
    padding: 1em 0;
    background-color: white;
    margin: 0 auto;
  }
  padding-bottom: 0;
}
