

.ad-simple-container {
    display            : flex;
    margin             : 0;
    width              : 100%;
    justify-content    : center;
    align-items        : center;
    background-repeat  : no-repeat;
    background-size    : contain;
    background-position: center center;
}

.ad-simple-link {
    width: 70%;
    max-width: 750px;
}

.ad-simple-img {
    width: 100%;
    height: auto;
}
