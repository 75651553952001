
@import "../../../data/config-scss/variables.scss";


$searchFormToggleButtonBgColor: $color1;


#search-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .75em .75em .2em;
    flex-shrink: 0;

    section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


// Simple search field toggle

#sfs-toggle-btns {
    display: flex;
    width: fit-content;
    margin-bottom: .5em;
    font-size: .9em;
    overflow: hidden;
    border-radius: 8px;

    div {
        padding: .6em 1.1em .5em;
        background-color: $searchFormToggleButtonBgColor;
        cursor: pointer;
        color: white;
        opacity: .8;
        -webkit-tap-highlight-color: transparent;
    }

    .sfs-tb-selected {
        opacity: 1;
        pointer-events: none;
        cursor: default;
    }
}

// Buttons

#sfs-search-btn {
    margin: .5em 0;
}

#sfs-search-btn-small > div {
    padding: .5em .7em .4em;
    font-size: 1.1em;
}

#sfs-go-to-simple-btn,
#sfs-go-to-advanced-btn {
    // margin: .5em 0;
}

#sfs-edit-btn {
    margin-top: .5em;
}

.item-checkbox {
    background-color: lighten($lightGrey, 20%);
    color: #222;
    border-radius: 0.2rem;
    padding: 0.3rem;
    margin: 0.2rem 0.2rem;
    font-size: 0.7rem;
}

.result {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0.3rem;
    &-placeholder {
        color: $lightGrey;
    }
}