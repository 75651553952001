
@import "../../../../../data/config-scss/variables.scss";


.kl-scan-result-block {
    margin: 2em;
}

.klc-result-field {
    font-weight: bold;
    color: darkgrey;
    font-size: .95em;
    margin: 0.7em .5em 0;
    display: inline-block;
}
