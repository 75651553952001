
@import "../../../data/config-scss/variables.scss";

.type-bar {
    display   : flex;
    margin-top: .3em;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.type-bar-icon {
    margin-left : .5em;
    font-size   : .9em !important;
    margin-right: 1em;
    color       : $typeBarIconColor;
    transition  : transform .4s;
}
.type-bar-icon-open {
    transform: rotate(-180deg);
}

.type-bar-label {
    flex-grow  : 1;
    font-weight: bold;
    text-align : center;
    color      : $typeBarColor;
}

.type-bar-count {
    display: flex;
    flex-basis: 2.5em;
    justify-content: space-around;

    span {
        padding         : .2em .3em 0;
        background-color: lighten( $separatorBackgroundColor, 7% );
        border-radius   : 5px;
        color           : $typeBarColor;
    }
}

.type-bar-content {
    opacity   : 1;
    overflow-y: hidden;
    transition: opacity .5s;
}
