.rc-rate {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  outline: none;
}
.rc-rate-disabled .rc-rate-star:before,
.rc-rate-disabled .rc-rate-star-content:before {
  cursor: default;
}
.rc-rate-disabled .rc-rate-star:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.rc-rate-star {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  transition: all .3s;
  color: #e9e9e9;
  cursor: pointer;
  line-height: 1.5;
}
.rc-rate-star-first,
.rc-rate-star-second {
  transition: all .3s;
}
.rc-rate-star-focused,
.rc-rate-star:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.rc-rate-star-first {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.rc-rate-star-half .rc-rate-star-first,
.rc-rate-star-half .rc-rate-star-second {
  opacity: 1;
}
.rc-rate-star-half .rc-rate-star-first,
.rc-rate-star-full .rc-rate-star-second {
  color: #f5a623;
}
.rc-rate-star-half:hover .rc-rate-star-first,
.rc-rate-star-full:hover .rc-rate-star-second {
  color: #f8c165;
}
