
.generic-input {
    padding: .7em .9em .7em;
    // border : 1px solid b5b5b5;
    background-color: transparent;
    font-size: 1em;
    flex: 1;
    border: none ;
    border-bottom: 1px solid $lightGrey;
    &::-webkit-input-placeholder {
        color: #656555
    }
    &:focus{
        outline: none;
    }
}

input.generic-form-error {
    color: red;
}

input[readonly] {
    border-bottom : 1px solid $lightGrey;
    outline: none;
    cursor : default;
}
