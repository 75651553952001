
@import "../../../../data/config-scss/variables.scss";


#kl-status-block {
    padding: 1em 1.1em 0.9em;
    background-color: $klStatusBlockBgColor;
}

.kl-sb-eventname {
    font-size: 1.2em;
    font-weight: bold;
}

.kl-sb-contacts-row {
    display: flex;
    align-items: center;
    font-size: .9em;
    padding: .4em 0 .1em;
}


// Contacts total count

.kl-sb-count {
}


// Count separator

.kl-sb-count-separator {
    margin: 0 .5em;
    border-width: 0 1px 0 0;
    border-style: solid;
    height: 6px;
}
.kl-sb-cs-sync {
    border-color: $klAllSyncColor;
}
.kl-sb-cs-notsync {
    border-color: $klNotSyncColor;
}


// Sync label

.kl-sb-all-sync {
    color: $klAllSyncColor;
}
.kl-sb-nonsync-count {
    color: $klNotSyncColor;
}
