
.media-content-container-basic {
    display       : flex;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.media-content-label {
    text-align: center;
    margin-top: 1em;
}

.media-buttons-container {
    display        : flex;
    flex-grow      : 1;
    margin         : 5%;
    justify-content: space-around;
    align-items    : center;
}



$maxMediaButtonSize: 200px;

.basic-media-button {
    margin: 1em;

    div {
        width : inherit;
        height: inherit;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }
}

// Landscape
@media screen and (orientation: landscape) {
    .basic-media-button {
        flex  : 1 1 25%;
        height: $maxMediaButtonSize;
        width:100%;
        max-width: $maxMediaButtonSize;
    }
}

// Portrait
@media screen and (orientation: portrait) {

    // wrap allowed = icons display multi-line
    .media-buttons-container {
        flex-wrap: wrap;
        //flex-direction: column;
    }

    .basic-media-button {
        flex  : 1 1 40%;
        height: $maxMediaButtonSize;
        width:100%;
        max-width: $maxMediaButtonSize;
    }
}

// Portrait on small screens
@media screen and (orientation: portrait) and (max-device-width: 580px){
    .basic-media-button {
        height: 105px;
        width:100%;
        max-width: 105px;
    }
}
