@import "../../../data/config-scss/variables.scss";

.ic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: $pageBackgroundColor;
  max-width: 350px;
  .ReactCrop {
    max-width: 90%;
    max-height: 90%;
  }
}

.ic-buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 1em;
  width: 100%;
  div {
    font-size: 2.8em;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
}

.ic-cancel {
  background-color: $imageCropCancelBtnColor;
}

.ic-confirm {
  background-color: $imageCropConfirmBtnColor;
}

