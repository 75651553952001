
@import "../../../data/config-scss/variables.scss";
@import "../../scss/mixins.scss";


.mobilespot-bar {
    display        : flex;
    flex-shrink    : 0;
    flex-direction : row-reverse;
    margin         : 3px 1px 1px 0;
    padding        : 2px .4em 3px 0;
    justify-content: space-between;
    font-size      : .7em;
    color          : $mobileSpotBarColor;
    cursor         : pointer;
    -webkit-tap-highlight-color: transparent;

    @include bg-gradient-l2r(transparent, $color1);

    .link.terms-of-use {
        color: darken( $mobileSpotBarColor, 30% );
    }
}

.is-cordova.is-iphonex .mobilespot-bar .link {
    padding: 0 .4em;
}


// On iPad, no mobile-spot bar to be able to see all tiles
/*@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)  {
    .mobilespot-bar {
        display: none;
    }
}*/
