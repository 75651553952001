@import "../../../data/config-scss/variables.scss";

.MuiDialog-paper {
  width: 90%;
}
.form-modal {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* for Firefox */
  min-height: 0;
}
.form-modal-body {
  flex-grow: 1;
  overflow: auto;
  /* for Firefox */
  min-height: 0;
}
.form-modal-footer {
  background-color: $color2;
  margin: 20px 0 0 0;
  padding: 10px 20% 10px 20%;
}
.form-modal-title {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 1em;
  color: dimgrey;
}
.form-modal-subtitle {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 1em;
  color: dimgrey;
}
.form-modal-textarea {
  width: 100%;
  min-width: 200px;
  height: 180px;
  resize: none;
  position: relative;
  margin: auto;
  padding: 10px;
  box-sizing: border-box;
  border: solid lightgrey;
}
.form-modal-input-container {
  display: flex;
	justify-content: center;
}
.form-modal-input-containerIn {
  display: flex;
  flex-direction: column;
  flex: 0.8;
  max-width: 400px;
}
.form-modal-input {
  // width: 80%;
  min-width: 200px;
  // max-width: 400px;
  height: 40px;
  position: relative;
  // margin: auto;
  padding: 10px;
  box-sizing: border-box;
  border: solid lightgrey;
}
.form-modal-input-error {
  border: solid red;
}
.form-modal-validation-message {
  color: red;
}
@media (min-aspect-ratio: 1/2) and (max-width: 400px) {
  .form-modal-textarea {
    height: 125px;
  }
}
.form-modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.form-modal-button {
  cursor: pointer;
  background-color: $titleFontColor;
  color: $color2;
  padding: 5px;
  border-radius: 5px;
}
#form-modal-deleteButton {
}
#form-modal-closeButton {
}

.share-btn-mail {
  color: #009766;
}
.share-btn-facebook {
  color: #4267b2;
}
.share-btn-linkedin {
  color: #0077b5;
}
.share-btn-twitter {
  color: #1da1f2;
}

#form-screen {
  .content {
    padding-bottom: 6em;
  }
}

.fm-icon {
  display: inline-block;
  width: $klFormMarginLeft;
  font-size: 1.15em;
  text-align: center;
}

.fm-title {
  padding: 2.3em 2em 2.3em $klFormMarginLeft;
  font-weight: bold;
  font-size: 0.9em;
  background-color: $klFormTitleBgColor;
}

// Form row

.content .clickableInput {
  display: flex;
  align-items: center;
  padding: 1.2em 2em 1.2em 0;
  border-bottom: 1px solid #efefef;
  font-size: 0.95em;
  min-width: 200px;
  cursor: pointer;
}

.fm-icon-check {
  color: $klCheckColor;
}

.fm-label-and-value {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .fm-input-placeholder {
    color: $lightGrey;
  }
}
.fm-value {
  padding-top: 0.4em;
}

.fm-chevron {
  color: $klChevronColor;
}

.content .fm-field-switch {
  padding-right: 1em;

  .input-switch-container {
    margin: 0;
  }
}

.fm-modal {
  .generic-field-row-chkbox {
    padding-top: 0;
    background-color: $klCheckBoxContainerBgColor;
    font-size: 1.1em;

    .generic-chkbx {
      color: $klCheckBoxColor;
    }
  }
}

// History

.fm-history {
  display: flex;
  margin: 2.5em 2em 0 $klFormMarginLeft;
  color: $klFormHistoryColor;
  font-size: 0.9em;
}

// History column1
.fm-history-col1 {
  flex: 0 0 1.7em;
}
.fm-history-icon {
}

// History column 2
.fm-history-col2 {
}
.fm-history-title {
  padding-top: 1px;
}
.fm-history-entries div {
  padding-top: 0.7em;
}
