
.activities-content {
    cursor: pointer;
    align-items:center;
    * {
        pointer-events: none;
    }
}

.appointment-request-sent {
    width: 100%;
    text-align: center;
}

.cats .list-el-counter {
    flex: 0 0 auto;
}

.fiche .cat-level-3 {
    padding-left: 20px;
}
.fiche .cat-level-4 {
    padding-left: 40px;
}
.fiche .cat-level-5 {
    padding-left: 60px;
}