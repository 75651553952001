
@import "../../../data/config-scss/variables.scss";

$titleBarHeight: 3.3em;


.fd-selected {
    color: $fdSelected;
}


#filter-dialog {
    display: flex;
    flex-direction: column;
    height: inherit;
    color: $fdDarker;
    font-size: .9em;
    font-weight: bold;

    .flex-row {
        display: flex;
        flex-direction: row;
    }
    .flex-col {
        display: flex;
        flex-direction: column;
    }
    .flex-align-center {
        align-items: center;
    }
}

.is-ios #filter-dialog {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    height: 100%;
}

.is-ios10 #filter-dialog {
    height: 100%;
}


@media only screen and (min-width : 1024px) {
    .clear-btn {
        padding-left:2em;
        flex-grow:1;
    }
}

#fd-back-button {
    margin-left: .5em;
    margin-right: .4em;
    font-size: 1.4em;
    cursor: pointer;
    color: $fdLinkColor;
    
}
.back-button-active{
    color: $fdSelectedIcon !important;
}
.container-fd-header {
    font-size: 1.3em;
    background-color: $fdLight;
    height: $titleBarHeight;
    border-bottom: 1px solid $fdLighter;

    #fd-back-button {
        margin-top:  .15em;
    }

    .fd {
        flex-grow:1;
        justify-content: space-evenly;
    }
}

.fd-top-bar {
    justify-content: space-between;
    font-size: 1.2em;
    max-height: 1.4em;

    .return-btn {
        color: $fdSelectedIcon;
        margin: 0.3em 0;
        font-size: 0.8em;
        font-weight:normal;
        cursor: pointer;
    }
    .clear-btn {
        margin: 0 .6em;
        font-size: 0.8em;
        color: $fdLinkColor;
        cursor: pointer;
        font-weight: normal;
        align-self: center;
    }
}

.fd-bottom-bar {
    justify-content: center;
    font-size: .7em;
    align-items: baseline;
    margin-bottom:.1em;

    .fa {
        font-size: .8em;
    }
    .results-counter {
        padding: 0 .3em;
        font-size: 1.2em;
        letter-spacing: .1em;
    }
    .exhibitor-label {
        font-size: 1.2em;
        font-weight: normal;
        text-transform: lowercase;
        max-padding-bottom:.1em;
    }
}

.container-fd-header-disable {
    background-color: $fdLight;
    height: $titleBarHeight;
    border-bottom: 1px solid $fdLighter;
    text-align: center;
    font-size: 1.3em;

    .filter-label {
        font-weight: bold;
        flex-grow:1;
        padding-right: 2em;
    }
}

/* ------------ */
/* Top cats row */

#filter-dialog {
    .t-tabs-container {
        flex-shrink: 0;
        border-bottom: 1px solid $fdMid;
        background-color: white;
    }
    &.fd-has-topcat-selected .t-tabs-container {
        border-color: $fdSelected;
        background-color: $fdLight;
    }

    .t-tab-selected {
        border-bottom-color: $fdSelected;
    }
}

.fd-top-cat {
    display: flex;
    align-items: center;
    height: 3.3em;
    padding-right: .4em;
    cursor: pointer;
    pointer-events: none;

    .fd-checkbox {
        padding-right: 0;
        pointer-events: auto;
        opacity: .3;

        * {
            pointer-events: none;
        }
    }
}
.t-tab-selected .fd-checkbox {
    opacity: 1;
}
.fd-top-cat-right {
    display: flex;
    padding: 0 .2em;
    flex-direction: column;
    text-align: center;
    word-break: break-word;
}
.fd-top-cat-icon {
    font-size: 1.6em;
    text-align: center;
}

/* ------------------------------- */
/* Sub categories and close button */

#fd-content {
    height: 100%;
    flex-grow: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 .2em;
    background-color: $fdLightest;
}
.is-iphonex #fd-content {
    margin-bottom: 20px;
}
.fd-content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.fd-subcat {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $fdLighter;

    /* Handling height */
    min-height: 2.8em;
    padding: .4em 0;
}
.fd-subcat-clickable {
    cursor: pointer;
    padding-right:2em;
}
.fd-subcat.is-navigated-cat {
    color: $fdSelected;
    background-color: white;

    .fd-chevron {
        display: none;
    }
}

.fd-cat-nav-back-icon {
    font-size: 1.6em;
    margin-right: 0.3em;
    color: $fdMid;
    cursor: pointer;
    pointer-events: none;
    fill: $fdSelected;
}

.fd-checkbox {
    flex: 0 0 1em;
    margin: 0 .2em 0 .4em;
    padding: .5em;
    text-align: center;
    cursor: pointer;

    .fd-selected {
        border-color: $fdSelected;
    }

    span {
        display: inline-block;
        height: .9em;
        width: .9em;
        padding: .3em;
        font-size: 1em;
        border-radius: 50%;
        border: 1px solid $fdLighter;
        background-color: $fdSelected;
        color: white;
        pointer-events: none;
    }

    .check-icon {
        // checked icon is not very well centered
        padding: .3em .35em .3em .25em;
    }
}
.fd-top-cat{
  .fd-checkbox {
      padding-left:0;
      margin-left:0;
  }  
}
span.fd-unchecked {
    border-color: $fdDarker;
    background-color: transparent;
}

.fd-subcat-title {
    flex-grow: 1;
    padding: .1em .8em 0 0;
    pointer-events: none;
}

@media only screen and (min-width : 768px){
    .fd-subcat-title {
        flex-grow:inherit;
    }
}


.fd-chevron {
    margin-right: .2em;
    color: $fdMid;
    pointer-events: none;
    transition: transform .4s;
    color: $fdSelected;
}
.fd-chevron-unfolded {
    transform: rotate(90deg);
}

.fd-subsubcats {
    height: 100%;
    margin-left: 1.4em;
    flex-shrink: 1;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
