

.ad-banner-container {
    display            : flex;
    margin             : 0;
    width              : 100%;
    flex-grow          : 0;
    flex-shrink        : 0;
    overflow           : hidden;
    justify-content    : center;
    align-items        : center;
    background-repeat  : no-repeat;
    background-size    : contain;
    background-position: center center;
    -webkit-tap-highlight-color: transparent;
}

.ad-banner {
    display: flex;
    width  : 100%;
    justify-content: center;

    &[href]img {
        cursor: pointer;
    }
}

.ad-redirect {
    cursor: pointer;
}
