
@import "../../../../data/config-scss/variables.scss";


.klipso-leads-header {
    display: flex;
    flex: 0 0 $klHeaderHeight;
    justify-content: center;
    align-items: center;
    padding-top: .1em;
    font-size: 1.2em;
    letter-spacing: 1px;
    color: $klHeaderColor;
    background-color: $klHeaderBgColor;

    .flag-select {
        transform: scale(.6);
        opacity: .85;

    }
}

.klh-left,
.klh-right {
    flex: 0 0 80px;
    display: flex;
}

.klh-center {
    flex: 1 0 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.klh-title {
    font-weight: bold;
    margin: 0.1em 0;
    width:138px;
    height:31px;
    background-image: url('../../../../data/public/icons-svg/klipso/klipsoLogo.png');
    background-size: 138px 31px;
}
.klh-subtitle {
    font-size: .7em;
}

.klh-close-btn {
    padding: 0.6em; // easily tappable
    cursor: pointer;
    font-size: 1.3em;
    color: $klHeaderCloseBtnColor;
    -webkit-tap-highlight-color: transparent;
    &.klh-close-label {
        padding: 0;
        font-size: 0.6em;
        font-weight: 600;
        text-decoration: underline;
    }
}
