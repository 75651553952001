
[id=intersticiel-container] {
    position: absolute;
    z-index: 10100; // Always above onsen modals

    & > div {
        position: fixed;
        z-index : 5;
        top     : 0;
        left    : 0;
        width   : 100%;
        height  : 100%;
        background-color: red;
    }

    .img-container {
        display        : flex;
        width          : 100%;
        height         : 100%;
        align-items    : center;
        justify-content: center;
    }
}

.interstitiel-close-container {
    position  : absolute;
    right     : 0;
    top       : 10px;
    padding   : .4em;
    cursor    : pointer;
    opacity   : 0;
    transition: opacity .4s;
    padding-top : env(safe-area-inset-top);
}

.interstitiel-close-sub-container {
    display        : flex;
    padding        : .4em .1em .3em .9em;
    font-size      : 1.2em;
    align-items    : center;
    border-radius  : 1px;

    & > div {
        padding-right: .6em;
        font-size    : .8em;
    }
}