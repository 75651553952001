
@import "../../scss/tile-click-effect.scss";


/* container */
[id=profile-buttons] {
    flex-grow     : 1;
    display       : flex;
    flex-direction: column;
    align-items   : center;
}


/* LANDSCAPE MODE */
/*@media only screen and (orientation : landscape) {

    [id=profile-buttons] {
        flex-direction: row;
    }

    .choose-profile-button {
        flex-basis    : 40%;
    }
}*/

/* button */
.choose-profile-button {
    /* important for absolute positioning of child elements (.effect-container) */
    position: relative;

    width: 80%;

    flex-grow          : 1;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center center;
    overflow           : hidden;
    cursor             : pointer;

    @include tile-click-effect();
}

@media only screen and (min-width: 320px) {
    #profile-buttons .choose-profile-button {
        max-width: 280px;
        margin   : 1.5em;
    }
}
@media only screen and (min-width: 380px) {
    #profile-buttons .choose-profile-button {
        max-width: 320px;
        margin   : 2em;
    }
}
@media only screen and (min-width: 480px) {
    #profile-buttons .choose-profile-button {
        max-width: 380px;
    }
}
@media only screen and (min-width: 580px) {
    #profile-buttons .choose-profile-button {
        max-width: 500px;
    }
}
@media only screen and (max-height: 480px) {
    #profile-buttons .choose-profile-button {
        margin: .5em 1em;
    }
}

.profile-button-label-container {
    display: flex;
    align-items: center;
    margin   : .3em;
    font-size: 1.4em;
    vertical-align: middle;
    background-color: rgba(33,33,33,.4);
}

$marginValue: .3em;
.profile-button-label {
    flex-grow: 1;
    margin   : $marginValue;
    font-size: 1.4em;
}
.profile-button-icon {
    margin-right: $marginValue;
}


.profile-lock-icon {
    margin: 0 .5em;
}
