@import "../../../data/config-scss/variables.scss";

.alphalist-component {
    display: flex;
    height: 100%;
}

.alphalist-container {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

// List on the right, index on the left
.alphalist-container-right {
    order: 1;
}