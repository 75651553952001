
[id=mobigeo-page] {
    background-color: white;
}

[id=mobigeo-container-wrapper] {
    width : 100%;
}

[id=mobigeo-container] {
    height: 100%;
    width : 100%;
}

.map-loader {
    display: flex;
    height : 100%;
    width  : 100%;
    justify-content : center;

    div {
        display: flex;
        flex-direction: column;
        justify-content : center;
        height: 100%;
    }

    ons-progress-circular {
        width : 100px !important;
        height: 100px !important;
    }
}
