
.inbox-msg-list-container {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.inbox-empty {
    margin-top: 10%;
    font-size : 1.1em;
    text-align: center;
    user-select: none;
}