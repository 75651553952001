@import "../../../data/config-scss/variables.scss";

[id=modalPrivacy-container] {
    position: absolute;
    z-index: 10100; // Always above onsen modals

    & > div {
        position: fixed;
        z-index : 5;
        top     : 0;
        left    : 0;
        bottom  : 0;
        right   : 0;
        width: 92%;
        height: 95%;
        padding: 4%;
        background-color: white;
    }
}

.modal-content {
    display        : flex;
    height         : 100%;
    flex-direction: column;
    align-items    : center;
    justify-content: center;
    z-index   : 10; // higher than left panel in two-columns mode
    width     : 100%;
    background-color: white;
}
.modal-privacy-body {
    align-items    : center;
    text-align: -webkit-center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index   : 10; // higher than left panel in two-columns mode
    width     : 84%;
    max-height :70%;
    margin: 3%;
    padding: 5% 5% 5% 5%;
    background-color: white;
}
.modal-privacy-button {
    align-items    : center;
    justify-content: center;
    width: 90%;
    margin: 5%;
    padding: 5%;
    border-radius: 4%;
}

