
@import "../../../data/config-scss/variables.scss";

.list-component {
    flex-grow : 1;
    margin    : 0;
    padding   : 0;
    list-style: none;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    font-size : 1em;
    color : $fontColor;

    li {
        display      : flex;
        padding      : .7em 0 .7em .5em;
        align-items  : center;
        border:  none;
        border-left: 1px solid $listBorderColor ;
        border-right: 1px solid $listBorderColor;
        border-top: 1px solid $listBorderColor;
    
        user-select: none;
    }

    &.happenings{
        padding: 0 0.5rem;
        li {
            padding: 0;
            margin: 1em 0;
            &.list-happening {
                display: flex;
                flex-direction: column;
            }
            .item-list-itinerary{
                font-size: 1.3em;
                align-self: flex-end;
                padding: 0.3rem 1.2rem;
                margin-bottom: 1em;
                .list-el-btn-text{
                    padding-top: 0.1rem;
                }
            }
            .items-list-description {
                font-size: 1em;
                padding: 0.5rem 0.2rem;
                align-self: normal;
            }
        }
    }

    /* li:first-child {
        margin-top: .3em;
    } */

    li:last-child {
        border-bottom: none;
    }

    li.emphasised {
        @include emphasized();
    }

    li.no-previous-sibling {
        padding-left: 1em;
    }

    /*li:nth-child(odd) {
        background-color: lighten( $color1, 64% );
    }*/


    .list-el-btn {
        margin-right : .3em;
        padding      : 7px 6px 1px;
        font-weight  : bold;
        font-size    : .9em;
        color        : white;
        border-radius: 2px;
        white-space  : nowrap;
        cursor       : pointer;
    }
    /* 'Go to' button */
    .go-to {
        background-color: $color1;
    }

    /* contact */
    .list-el-contacted {
        width: 4em;
        text-align: center;
        margin-right: .3em;
    }

    /* Fav icon */
    .star {
        flex-shrink: 0;
        padding: 0;
        font-size: 1.6em;
        line-height: 1.25em;
        height: 1em;
        margin-right: .6em;
        color: $favIconColor;
    }

    .noteExcerpt{
        margin-top:.3em;
        align-self: flex-start;
        padding-right:1em;
    }
    .itemWrapper{
        flex-direction: column;
        display: flex;
        flex-grow: 1;
    }
}

.not-ios.is-web .list-component li[data-is-clickable]:hover {
    background-color: $listElBackgroundColorHover;
    cursor: pointer;
}

// Special participants style override
.participants .list-el-details {
    display: flex;
    flex-direction: column;
    align-items: start;

    .text-minor-bis {
        margin-left: 0;
    }
}

.list-is-fast-and-ugly {
    span {
        padding: .1em 0 0 .5em;
    }
}


/* counter (for categories) */
.list-el-counter {
    display: flex;
    min-width: 2.5em;

    span {
        font-size: 1em;
        line-height: 1em;
        max-height      : 1.1em;
        min-width       : 1.5em;
        margin-right    : .4em;
        padding         : 0.4em 0.3em 0.4em 0.3em;
        text-align      : center;
        justify-content : right;
        background-color: $listElCounterBackgroundColor;
        border-radius   : 4px;
    }
}

/* icon/image */
.list-el-image {
    display          : inline-block;
    flex             : 0 0 $listElementImageSize;
    height           : $listElementImageSize;
    width            : $listElementImageWidth;
    margin-right     : .5em;
    background-size  : cover;
}
.list-el-dual-images{
    display:flex;
    flex-direction:column;
}
.under-image{
    margin-top:0.3em !important;
    max-height:3em;
}
.list-el-icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.list-el-image-small {
    display          : inline-block;
    flex             : 0 0 $listElementImageSize;
    height           : $listElementImageSmallWidth;
    width            : $listElementImageSmallWidth;
    margin     : 0 .5em;
    background-size  : cover;
}

/* row text content */
.list-el-text {
    display    : flex;
    flex-direction: column;
    flex-grow  : 1;
    word-break: break-word;
}

.is-ms .list-el-text {
    line-height: 1.2em;
    overflow   : hidden;
}

.list-el-details {
    display    : flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.text-minor {
    color: $listTextMinorColor;
    font-size: $listTextMinorFontSize;
}

.text-minor-bis {
    color: $listTextMinorColor;
    font-size: $listTextMinorFontSize;
    font-style: italic;
    margin-left: .8em;
}

.list-el-event {
    flex        : 0 0 4em;
    margin-right: .8em;
    font-size   : .9em;
    text-align  : right;
    color       : $list-el-event;

    .start-date {
        font-weight: 700;
        font-size  : 1em;
    }

    .start-hour {
        font-weight: bold;
        font-size  : 1.2em;
    }

    .end-date,
    .end-hour {
        color: $endHourColor;
    }
}


/* chevron */
.list-el-chevron {
    //padding: .1em .3em 0 .1em;
    color  : $listChevronColor;
}

.list-el-chevron.nochevron{
    visibility:hidden;
}


.items-list {
    flex-grow : 1;
    height: 100%;

    & > div {
        position: relative;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
}


.items-list-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    object-fit: cover;
    height: 150px;
    width: 100%;
    .list-happening & {
        height: auto;
        width: auto;
        max-height: 300px;
        max-width: 100%;
    }
}

.items-list-description {
    font-size: 0.8em;
    font-weight: 300;
    padding: 2%;
    padding-top: 0%;
    color: #949FA7;
}

.item-list-itinerary {
    display: flex;
    align-items: center;
    background-color: #E70055;
    border-radius: 0.4em;
    float: right;
    padding: 4px 7px;
    margin-bottom: 10;
}

.list-el-btn-text {
    font-weight  : 300;
    font-size    : 0.9em;
    color        : white;
    border-radius: 2px;
    white-space  : nowrap;
    cursor       : pointer;
}

.list-el-btn-icon {
    margin-left: 8px;
    font-weight  : 300;
    font-size    : 0.9em;
    color        : white;
    border-radius: 2px;
    white-space  : nowrap;
    cursor       : pointer;
}