
@import "../../../../data/config-scss/variables.scss";


.kl-overlay-loader {
    position: absolute;
    z-index: 1;
    display: flex;
    height: 115%; // 115% for iOS, don't ask me why...
    width: 100%;
    background-color: $klOverlayLoaderBgColor;
}

.kl-ol-subcontainer {
    display: flex;

    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .MuiCircularProgress-colorPrimary {
        color: $klThemeColor2;
    }
}

.kl-overlay-loader.kl-ol-top {
    padding-top: 20%;
}
.kl-overlay-loader.kl-ol-center {
    // padding-top: 60%;
    justify-content: center;
}
.kl-overlay-loader.kl-ol-bottom {
    padding-top: 100%;
}

.kl-overlay-loader-label {
    padding: 1em;
    margin-bottom: 1em;
    background-color: $klOverlayLoaderLabelBgColor;
}