
@import "../../../../../data/config-scss/variables.scss";


.kl-main-sa {
    display: flex;
    flex-direction: column;

    .search-field-container {
        padding: 0.5em 1em;
        border-bottom: $klSearchFieldBottomBorder;
    }

    .search-field-cancel-container {
        padding-left: 1em;
    }
    .search-field-cancel {
        font-weight: normal;
        color: $klTextColor;
    }

    // Single data type
    .type-bar {
        display: none;
    }
}