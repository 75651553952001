
.kl-disclaimer {
    padding: 1em;
    text-align: center;
    font-size: .9em;

    li {
        margin: .8em 0;
        text-align: initial;
    }
}
