
.t-tabs-container {
    width: 100%;
    padding-top: 0.5em;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    flex-shrink: 0;
}

.t-tabs {
    display: flex;
    position: relative;
    left: 0;
    transition: left .3s;
}

.t-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .2em 0;
    flex-shrink: 0;
    text-align: center;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    -webkit-tap-highlight-color: transparent;
}
.t-tab-disabled {
    color: grey;
}

.t-tab-content-wrapper {
    padding: 0 .4em;
    pointer-events: none;
}
