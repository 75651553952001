
.input-switch-container {
  display: flex;
  justify-content: space-between;
  margin: 1em 3em 0.2em;
}

.input-switch-label {
  user-select: none;
}
