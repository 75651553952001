@import "../../../data/config-scss/variables.scss";

.contact-button-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 1em;
    margin-bottom: 1.8em;
    font-size: 1.1em;
    align-items: flex-end;
    margin-right: 0.5em;
}
.fiche-entreprise{
  display: flex;
  flex-direction: column;
}
.fiche-logo{
  img{
    margin-top: 0.2em;
  }
}
.fiche-perso{
  margin-bottom: 1em;
  font-size: .95em;
}
.fiche-perso > *{
  margin-bottom: 0.2em;
}
.fiche-perso div:last-child{
  margin-bottom: 0;
}
.fiche-perso-type{
  line-height: 1em;
}
.fiche-row2{
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.contact-btn:disabled{
  cursor:default;
}
