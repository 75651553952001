
.detail-data-list {
    display: flex;
    flex-direction: column;
    margin: .6em 0 .35em;
    
    .fiche-related-data {
        flex-grow: 1;
    }

    .list-component {
        padding-left: .4em;
    }

    .simple-loader {
        font-size: .9em;
    }
}
