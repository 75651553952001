
// Background Gradient: Left to Right
// @see https://www.himpfen.com/linear-gradient-background-sass-mixins/

@mixin bg-gradient-l2r($start-colour, $end-colour) {
    background-color: $start-colour;
    background-image: -webkit-gradient(linear, left, right, from($start-colour), to($end-colour));
    background-image: -webkit-linear-gradient(to right, $start-colour, $end-colour);
    background-image:    -moz-linear-gradient(to right, $start-colour, $end-colour);
    background-image:     -ms-linear-gradient(to right, $start-colour, $end-colour);
    background-image:      -o-linear-gradient(to right, $start-colour, $end-colour);
    background-image:         linear-gradient(to right, $start-colour, $end-colour);
    filter:            progid:DXImageTransform.Microsoft.gradient(start-colourStr='#{$start-colour}', end-colourStr='#{$end-colour}');
}
