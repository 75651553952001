.checkbox {
	cursor: pointer;
	color: rgb(170, 170, 170);
	border-width: 1px;
	border-style: solid;
	border-color: rgb(170, 170, 170);
	border-radius: 1px;
	padding: 16px;
	flex: 1 1 0%;
	margin-bottom: 16px;
	margin-right: 0px;
	position: relative;

	.checkbox-input {
		position: absolute;
		opacity: 0;
		height: 100%;
		width: 100%;
		box-sizing: border-box;
		top: 0;
		left: 0;
	}

	.checkbox-content {
		display: inline-flex;
		width: 100%;

		.checkbox-title {
			flex: 1 1 0%;
		}

		.checkbox-toggle {
			width: 10px;
			height: 10px;
			padding: 3px;
			background-color: rgb(255, 255, 255);
			border-width: 2px;
			border-radius: 50%;
			border-style: solid;
			border-color: rgb(170, 170, 170);
			margin-right: 0px;

			div {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: rgb(170, 170, 170);
			}
		}
	}

	&.checkbox-active {
		color: rgb(0, 154, 0);
		border-color: rgb(0, 154, 0);

		.checkbox-toggle {
			border-color: rgb(0, 154, 0);

			div {
				background: rgb(0, 154, 0);
			}
		}
	}
}
