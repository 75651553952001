.buttonClassName { // wtf?
    min-width: 80px;
    font-family: 'exo2-regular';
    font-weight: 400;
    background: linear-gradient(to top,#D0D0D0,#E0E0E0);
    border: none;
    border-radius: 3px;
    margin: 1em;
    margin-bottom: .5em;
    padding: .3em .5em;
    cursor: pointer;

    .content {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        user-select: none;
    }
}


