@import "../../../data/config-scss/variables.scss";

.embed-container { 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
    overflow: hidden; 
    height: 100%;
    width: 100%;
    // width: 80%; /* adjust to control the size of the green video container */
    margin: auto;
    border:1px solid $vimeoBorderColor;
} 
.embed-container iframe { 
    position: relative; 
    width: 100%; 
    height: 100%; 
}
.spinner{
    margin: auto;
    width: 24px;
    height: 24px;
    border: 2px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
}

@keyframes spin {
    100% {
      transform: rotate(360deg);
    }
}
