
.share-buttons-title {
    margin-bottom: .5em;
    text-align   : center;
    font-size    : 1.4em;
    color        : dimgrey;
}

.share-buttons {
    display        : flex;
    flex-wrap      : wrap;
    margin-bottom  : .6em;
    padding        : 0 1.5em;
    justify-content: center;

    a {
        padding: .2em .3em;
        text-decoration: none;
        font-size: 4em;
        cursor: default;
        -webkit-tap-highlight-color: transparent;
    }
    span {
        cursor: pointer;
    }
}
.share-btn-sms {
    background-color: #4267b2;
}

.share-btn-mail {
    background-color: #009766;
}
.share-btn-facebook {
    background-color: #4267b2;
}
.share-btn-linkedin {
    background-color: #0077B5;
}
.share-btn-twitter {
    background-color: #1DA1F2;
}
