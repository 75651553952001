
$margin: 4px 8px 4px 8px;

#poll-dialog {

    .generic-modal-content {
        // margin: 0 1em 1em;
        display: flex;
        flex-direction: column;
    }

    .colored-link {
        text-decoration: underline;
    }

    .close-contributions-dialog {
        padding: 0.4em;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.5em;
    }

    .error-message {
        color: red;
    }

    .title {
        color: #009a00;
        font-weight: bold;
        font-size : 1.2em;
    }

    .title-font.modal-title h2 {
        font-size: 1.1em;
    }

    .bold {
        font-weight: bold;
    }

    .title-font {
        color: #B0B0B0;
        font-weight: bold;
        padding-bottom: 0.8em;
    }

    .content-font {
        padding-top: 0.8em;
        position: relative;

        .scrl-ctrl {
            position: absolute;
            left: 0;
            top: -33px;
            display: flex;

            div {
                margin-right: 5px;
                background-color: white;
            }
        }
    }

    .choice-container {
        width: 100%;
        overflow: auto;

        textarea {
            width: 100%;
            box-sizing: border-box;
            min-height: 100px;
            border: 1px solid lightgrey !important;
            font-size: 0.9em;
        }

        textarea::placeholder {
            color: lightgrey;
        }

        textarea:focus {
            outline: none;
        }

        .textarea-count {
            color: lightgrey;
            font-size: 0.9em;
        }
    }

    .textarea-count {
        position: relative;
        float: right
    }

    .submit-code-btn {
        width: auto;
        display: inline-block;
        padding: .4em 1.7em .3em !important;
    }

    .invisible {
        display: none;
    }

    .poll-header {

    }

    .poll-body {
        // padding: 1em 0;
        overflow-y: scroll;
    }

    .poll-link {
        padding: 0 0 1em;
        font-size: 0.9em;
    }

    .poll-footer {
        margin-bottom: 1em;
    }

    .question_text {
        margin-bottom: 0.75em;
    }

    .poll-text {
        font-size : .9em;
    }

    .text-bottom {
        margin-bottom: .5em;
    }

    .poll-list {
        float  : left;
        margin : 0;
        padding: 0;
        list-style-type: none;
        width: 100%;

        .poll-item {
            padding: 10px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            border-bottom: 1px solid lightgrey;

            &:nth-last-child(1) {
                border-bottom: none;
            }

            .poll-state-icon {
                display        : flex;
                justify-content: center;
                align-items    : center;
                padding-left : 15px;
                padding-right  : 5px;
                min-width: 30px;
            }

            .poll-detail {
                flex: 0 1 auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;

                .poll-topic {
                    font-family: inherit;
                    text-align: left;
                    align-self: auto;
                    flex: 0 1 auto;
                    font-size : 0.8em;
                    color: lightgrey;
                    margin-bottom: 0.2em;

                    &.active {
                        color: black;
                    }
                }

                .poll-local-state {
                    align-self: auto;
                    flex: 0 1 auto;
                    font-size : 0.8em;
                    text-align: left;

                    &.notStarted {
                        color: #009a00;
                    }

                    &.started {
                        color: #0089D9;
                    }

                    &.submitted {
                        color: red;
                    }
                }
            }
        }
    }
}

.poll-form-row {
    margin-bottom: .5em;

    label {
        display   : inline-block;
        float     : left;
        clear     : left;
        width     : 25%;
        text-align: right;
        margin    : $margin;
    }
    input {
        display: inline-block;
        margin : $margin;
    }
}


@media screen and (max-width: 340px) {

    .poll-form-row label {
        text-align : left;
        margin-left: 2em;
    }
}


