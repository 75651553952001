
@import "../../../data/config-scss/variables.scss";


.flag-select {
    margin             : 0 .5em;
    height             : 64px;
    width              : 64px;
    background-size    : 64px 64px;
    background-position: -20% center;
    border             : 2px solid transparent;
    opacity            : .75;
    cursor             : pointer;
    transition : opacity .3s;

    &.is-current-lang {
        border-color : $currentLangBorderColor;
        border-radius: 40px;
        opacity      : 1;
    }
}
.is-web .flag-select:hover {
    opacity: 1;
}

[id=cl-langs] {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1.2em;
}

@media screen and (max-width: 360px) {
    .flag-select {
        height             : 55px;
        width              : 55px;
        background-size    : 55px 55px;
    }
}