
@import "../../../data/config-scss/variables.scss";


#synoptic-container {
    display: flex;
    flex-direction: column;
}

#synoptic-agenda-page {

    .t-tabs-container {
        color: white;
        background-color: $color2;
    }
    .t-tab {
        display: flex;
        justify-content: center;
        padding-bottom: .2em;
    }
    .t-tab-selected {
        border-bottom-color: $synopticAgendaTabSelected;
    }
}