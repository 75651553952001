$margin: 4px 8px 4px 8px;
$horizontalSeparatorBorder: 1px solid #eee;

.favorites-content {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch
}

.favorites-synchro-notice {
    line-height: 1.2em;
    padding: 1em .4em;
    margin-bottom: .5em;
    font-size: 0.8em;
    text-align: center;
    color: #434343;
    border-bottom: $horizontalSeparatorBorder;

    .refresh-grey {
        margin-left: 0.4em;
        font-size: 12px;
        background-color: #c0c0c0;
        display: inline-block;
    }

    .refresh-green {
        margin-left: 0.4em;
        font-size: 12px;
        background-color: #0c9e0c;
        display: inline-block;
    }

    .code {
        font-size: 1.4em;
        color: #0c9e0c;
        font-weight: bold;
        margin-left: 0.4em;
    }

    .synchronized {
        font-size: 1.4em;
        font-weight: bold;
    }
}

.user-icon.connected {
    background-color: green;
}

[id=favorites-code-dialog] {
    margin: 0 1em 1em;
    display: flex;
    flex-direction: column;

    // overflow-y: scroll;
    .close-favorites-code-dialog {
        padding: 0.4em;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.5em;
        background-color: rgba(0, 0, 0, 0.87);
    }

    .title-font {
        color: rgb(17, 9, 9);
        font-weight: bold;
        padding-bottom: 0.8em;
    }

    .content-font {
        padding-top: 0.8em;
        position: relative;
    }

    .body {
        // padding: 1em 0;
        overflow-y: scroll;

        .form-row {
            margin-bottom: .5em;

            input {
                display: inline-block;
                margin: $margin;
            }
        }
    }

    .choice-btn {
        margin-bottom: 1em;
        width: auto;
        display: inline-block;
    }

    .code-created {
        margin: 1em 0;
        color: #0c9e0c;
        font-weight: bold;
    }

    .notice {
        font-style: italic;
        font-size: 0.8em;
        margin-bottom: 0.8em;
    }

    .validation {
        font-style: italic;
        font-size: 0.8em;
        margin-top: 0.4em;
        margin-bottom: 0.8em;
    }

    .footer {
        margin-bottom: 1em;
    }
}

.favorites-options-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .download-btn {
        display: inline-block;
    }

    padding-top:.3em;
}